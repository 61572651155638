/*---------------------------------------------------------------------*/
/* Global Styles
/*---------------------------------------------------------------------*/
@tailwind base;
@tailwind components;
@tailwind utilities;

.reactEasyCrop_Container {
  position: relative !important;
  height: 90%;
  width: 100%;
}

.referralSlider .slick-next,
.referralSlider .slick-prev {
  z-index: 10000 !important;
}

.referralSlider .slick-prev:before,
.referralSlider .slick-next:before {
  color: #333333 !important;
}

@media only screen and (max-width: 600px) {
  .referralSlider .slick-prev:before,
  .referralSlider .slick-next:before {
    font-size: 36px !important;
    width: 50px !important;
  }
}
.review-card-inline {
  display: flex !important;
}

.vehicle-slider .slick-prev:before {
  content: '‹';
}
.vehicle-slider .slick-next:before {
  content: '›';
}
.vehicle-slider .slick-prev,
.vehicle-slider .slick-next {
  z-index: 50;
  width: 85px;
  top: 35%;
}
.vehicle-slider .slick-prev:before,
.vehicle-slider .slick-next:before {
  color: white !important;
  font-size: 80px;
}
@media only screen and (max-width: 1024px) {
  .vehicle-slider .slick-prev,
  .vehicle-slider .slick-next {
    top: 25%;
  }
}
@media only screen and (max-width: 900px) {
  .vehicle-slider .slick-prev,
  .vehicle-slider .slick-next {
    top: 40%;
  }
}
@media only screen and (max-width: 600px) {
  .vehicle-slider .slick-prev,
  .vehicle-slider .slick-next {
    top: 30%;
  }
}
.fnf-ribbon {
  /*clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);*/
  clip-path: polygon(21% 0, 0 50%, 21% 100%, 0 100%, 0% 50%, 0 0);
}

.rooftop-review-slider {
}
.rooftop-review-slider .slick-prev:before {
  content: '‹';
}
.rooftop-review-slider .slick-next:before {
  content: '›';
}
.rooftop-review-slider .slick-prev,
.rooftop-review-slider .slick-next {
  z-index: 50;
  top: 35%;
}
.rooftop-review-slider .slick-prev:before,
.rooftop-review-slider .slick-next:before {
  color: rgba(52, 52, 52, 0.6) !important;
  font-size: 80px;
}
@media only screen and (max-width: 1024px) {
  .rooftop-review-slider .slick-prev,
  .rooftop-review-slider .slick-next {
    top: 25%;
  }
}
@media only screen and (max-width: 900px) {
  .rooftop-review-slider .slick-prev,
  .rooftop-review-slider .slick-next {
    top: 15%;
  }
}
@media only screen and (max-width: 600px) {
  .rooftop-review-slider .slick-prev,
  .rooftop-review-slider .slick-next {
    top: 10%;
  }
}
.financing-banner-height {
  height: calc(100% - 40px);
  max-width: calc(100% - 55%);
  @media only screen and (max-width: 768px) {
    height: 115px;
  }
}
